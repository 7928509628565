<template>
	<SparesPermissions />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
//import SparesPermissions from '@/components/inventory/spares/index.vue';
import SparesPermissions from '@/components/inventory/Tabs/SparesTab/sparesTab.vue'

export default {
	name: 'SparesInventoryPage',
	components: { SparesPermissions },
};
</script>
