import readXlsxFile from "read-excel-file";
import { mapActions } from 'vuex';
import { $EventBus } from "@/main";
import moment from 'moment';
import { validateFileSize, validExtension } from '@/helpers/validationList';
import {
  isNotEmpty,
  hasExtension,
  hasTitles,
} from "@/helpers/validationList.js";
export default {
  name: 'ImportMassiveSpareDialog',
  data() {
    return {
      mode: 'FORM',
      selectedFile: null,
      importMassiveForm: {
        selectedFile: null,
      },
      fileRule: [
        (v) => !!v || 'Campo requerido',
        (v) =>
          validExtension(v, [

            '.xslx',
            '.xlsx',

          ]) || 'La extensión del archivo no es válida',
        (v) =>
          validateFileSize(v, 10) ||
          '"El archivo es demasiado grande. Máximo 10 MB',
      ],
      dialog: false,
      listError: [],
      valid: false,
      loading: false,
      filelist: [],
      savedSpares: [],
      headers: [
        { text: "Nombre", sortable: false, value: "name" },
        { text: "Área", sortable: false, value: "area" },
        { text: "Serie", sortable: false, value: "serial_number" },
        { text: "Tipo", sortable: false, value: "spare_type" },
        //{ text: "Costo", sortable: false, value: "cost" },
        //
        { text: "Número de repuesto", sortable: false, value: "num_spare" },
        // { text: "Etiqueta", sortable: false, value: "tag" },
        { text: "Modelo", sortable: false, value: "model" },
        // { text: "Observaciones", sortable: false, value: "observations" },
        { text: "Valor de compra", sortable: false, value: "purchase_value" },
        { text: "Fecha de compra", sortable: false, value: "purchase_date" },
        //
        // { text: "Estado", sortable: false, value: "status", hidden: true }
      ],
      headers2: [
        { text: "Nombre", sortable: false, value: "spare.name" },
        { text: "Tipo", sortable: false, value: "spare.spare_type" },
        { text: "Area", sortable: false, value: "spare.area" },
        { text: "Valor de compra", sortable: false, value: "spare.purchase_value" },
        { text: "Estado", sortable: false, value: "messages" }
      ],
    };
  },
  computed: {
    dialogWidth() {
      return this.mode !== 'FORM' ? 1200 : 500;
    },
    filteredHeaders() {
      return this.headers.filter((header) => header.value !== 'status');
    },
    hasImportPermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'listaRepuestos',
				'importarRepuestos'
			);
		},
  },
  methods: {
    ...mapActions('spare', ['createSpareMultiple', 'downloadSpareFormat']),
    dialogInput(value) {
      this.$refs.button.$el.blur();
      this.dialog = value;
      this.importMassiveForm = {
        selectedFile: null,
      };
      this.$refs?.form?.resetValidation();
      // if (!value) {
      //   this.$nextTick(() => {
      //     this.classificationForm = {
      //       name: "",
      //       area: null,
      //       responsible_id: null,
      //     };
      //     this.listError = [];
      //     this.$refs?.form?.resetValidation();
      //   });
      // }
    },
    getMessage(success) {
      return success
        ? { status: 'GUARDADO', color: 'green' }
        : { status: 'NO GUARDADO', color: 'red' };
    },
    async changeFile() {
      this.filelist = [];
      try {
        const file = this.importMassiveForm.selectedFile;

        if (hasExtension(file, [".xlsx"])) {
          const rows = await readXlsxFile(file);

          const titles = [
            "Nombre",
            "Área",
            "Número de serie",
            "Tipo de Repuesto",
            // "Costo",
            //"Responsable",
            "Número de repuesto",
            "Marca",
            "Etiqueta",
            "Modelo",
            "Observaciones",
            "Valor de adquisición",
            "Fecha de compra"
          ];
          if (isNotEmpty(rows) && hasTitles(rows, titles)) {
            rows.forEach((row, index) => {
              if (index > 0 && row[0] && row[1] && row[4]) {
                this.filelist.push({
                  name: row[0],
                  area: row[1],
                  serial_number: row[2] ? row[2] : null,
                  spare_type: row[3],
                  // cost: row[4] ? row[4] : null,
                  //responsible_id: row[5] ? row[5] : null,
                  num_spare: row[4] ? row[4] : null,
                  brand: row[5] ? row[5] : null,
                  tag: row[6] ? row[6] : null,
                  model: row[7] ? row[7] : null,
                  observations: row[8] ? row[8] : null,
                  purchase_value: row[9] ? row[9] : null,
                  purchase_date: row[10] ? row[10] : null,
                });
              }
            });
            this.mode = "UPLOAD";
          } else {
            this.mode = "FORM";
          }

        }
      } catch (e) {
        $EventBus.$emit(
          "showSnack",
          "error",
          e || "Ocurrio un error al leer el archivo."
        );
      }
    },
    async save() {
      const { ok, error, response } = await this.createSpareMultiple(this.filelist);
      if (ok) {
        // const assets = response;
        // this.savedSpares = Array.isArray(assets) ? assets : [];

        this.savedSpares = this.filelist.map((spare, index) => {
          return {
            ...response[index],
            // spare: response[index].spare || spare,
            spare: spare,
          };
        });

        this.mode = "SAVED";

        await this.$emit('getSpares');
        // $EventBus.$emit(
        // 	'showSnack',
        // 	'success',
        // 	//response?.messages[0] || 'Se ha registrado satisfactoriamente.'
        //   response[0].messages || 'Se ha registrado satisfactoriamente.'
        // );
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(", ") : errorMsm;
        $EventBus.$emit("showSnack", "error", errorMsm);
      }
    },
    async downloadTemplate() {
      try {
        const { ok, error } = await this.downloadSpareFormat();
        if (!ok) {
          console.error(error); // o muestra un mensaje de error usando una alerta/snackbar, por ejemplo.
        }
      } catch (ex) {
        console.error("Hubo un error al descargar el modelo:", ex);
      }
    },
    formatDate(dateString) {
      if (!dateString) {
        return '-';
      }
      return moment(dateString).format('DD/MM/YYYY HH:mm');
    },
    close() {
      this.dialog = false;
      this.mode = "FORM";
      this.importMassiveForm = {
        selectedFile: null,
      };
      this.savedSpares = [];
      this.$refs?.form?.resetValidation();
    },
  },
};
