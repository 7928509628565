import { mapState, mapActions } from "vuex";
import ValidationAlert from "@/components/global/ValidationAlert.vue";
export default {
  name: "UpdateAssetsDialog",
  data() {
    return {
      // dialog: false,
      listError: [],
      valid: false,
      loadingSave: false,
      assetsForm: {
        //
        originalAssignedUserId: null,
        //
        name: "",
        area: null,
        //responsible_id: null,
        assigned_user_id: null,
        serial_number: "",
        asset_type: null,
        cost: null,
        // unsubscribe_comment: "",
        // unsubscribe_file: "",
        comment: null
      },
      nameRules: [
        (v) => !!v || "Nombre de Activo es requerido",
        (v) => !!v && v.length <= 50 || "El nombre debe tener menos de 50 carácteres",
      ],
      areaRules: [(v) => !!v || "Área es requerida"],
      //responsablesRules: [(v) => !!v || "El responsable es requerido"],
      //assignedRules: [(v) => !!v || "El asignado es requerido"],
      serieRules: [
        (v) => !!v || "Número de serie es requerido",
        (v) => !!v && v.length <= 50 || "La serie debe tener menos de 50 carácteres",
      ],
      typeRules: [(v) => !!v || "El tipo es requerido"],
      costRules: [
        (v) => !!v || "Costo de activo es requerido",
        (v) => this.checkIntegerPart(v) || 'La parte entera debe tener un máximo de 6 dígitos',
        (v) => /^[0-9]*(\.[0-9]{0,2})?$/.test(v) || 'Solo se permiten números con hasta dos decimales'
      ],
      commentRules: [
        (v) => !!v || "El comentario es requerido",
        (v) => (!v || v.length <= 400) || "El comentario debe tener menos de 400 carácteres",
      ]
    };
  },
  props: {
    value: Boolean,
    item: Object,
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.assetsForm = {
          name: this.item.name,
          area: this.item.area.id,
          //responsible_id: this.item?.responsible_id?.id || null,
          assigned_user_id: this.item?.assigned_user_id?.id || null,
          serial_number: this.item.serial_number,
          asset_type: this.item.asset_type.id,
          cost: this.item.cost,
          comment: this.item?.comment || null
          //
        };
        this.originalAssignedUserId = this.item?.assigned_user_id?.id || null;
      }
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState("area", ["allAreas"]),
    ...mapState("assetType", ["allAssetTypes"]),
    ...mapState({
      responsibles: (state) => state.security.usersActive,
    }),
    sortedAllAreas() {
      return [...this.allAreas].sort((a, b) => a.name.localeCompare(b.name));
    },
    assignedUserChanged() {
      if (!this.originalAssignedUserId && !this.assetsForm.assigned_user_id) {
        return false;
      }

      if (!this.originalAssignedUserId && this.assetsForm.assigned_user_id) {
        return true;
      }

      if (this.originalAssignedUserId && !this.assetsForm.assigned_user_id) {
        return false;
      }

      return this.originalAssignedUserId !== this.assetsForm.assigned_user_id;
    },
  },
  methods: {
    ...mapActions("asset", ["updateAsset", "listAsset"]),
    ...mapActions("security", ["getUsersActive"]),
    ...mapActions("area", ["listAllAreas"]),
    ...mapActions("assetType", ["listAllAssetTypes"]),

    checkIntegerPart(value) {
      if (!value) {
        return true;
      }
      const stringValue = value.toString();
      const integerPart = stringValue.split('.')[0];
      return integerPart.length <= 6;
    },

    close() {
      this.dialog = false;
      this.assetsForm = {
        name: "",
        area: null,
        //responsible_id: null,
        assigned_user_id: null,
        serial_number: "",
        asset_type: null,
        cost: null,
        // unsubscribe_comment: "",
        // unsubscribe_file: "",
        comment: null
      };
      this.listError = [];
      this.$refs?.form?.resetValidation();
    },
    async save() {

      if (!this.$refs?.form?.validate()) {
        return;
      }

      this.listError = [];

      const cost = parseFloat(this.assetsForm.cost);

      const data = {
        ...this.assetsForm,
        cost,
      };

      // if(this.originalAssignedUserId === this.assetsForm.assigned_user_id){
      //   delete this.assetsForm.assigned_user_id;
      // }

      this.loadingSave = true;
      const { ok, error } = await this.updateAsset({
        id: this.item.id,
        data: data,
      });
      if (ok) {
        this.dialog = false;
        //emit
        await this.$emit('getAssets');
        //limpiar form
        this.assetsForm = {
          name: "",
          area: "",
          //responsible_id: "",
          assigned_user_id: "",
          serial_number: "",
          asset_type: "",
          cost: "",
          comment: ""
          // unsubscribe_comment: "",
          // unsubscribe_file: "",
        };
        this.$refs.form.resetValidation();
        this.loadingSave = false;
      } else {
        this.listError = Array.isArray(error?.data?.message) ? error?.data?.message : [error?.data?.message] || [];
        this.loadingSave = false;
      }
    },
    dialogInput(value) {
      this.dialog = value;
      if (!value) {
        this.$nextTick(() => {
          this.assetsForm = {
            name: "",
            area: null,
            //responsible_id: null,
            assigned_user_id: null,
            serial_number: "",
            asset_type: null,
            cost: null,
            // unsubscribe_comment: "",
            // unsubscribe_file: "",
            comment: null
          };
          this.listError = [];
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  components: {
    ValidationAlert,
  },
};
