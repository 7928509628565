import { mapState, mapActions } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
export default {
	name: 'CreateSparesDialog',
	data() {
		return {
			dialog: false,
			listError: [],
			valid: false,
			loadingSave: false,
			sparesForm: {
				name: '',
				area: null,
				responsible_id: null,
				serial_number: '',
				spare_type: null,
				purchase_value: null,
			},
			nameRules: [
				(v) => !!v || 'Nombre de Repuesto es requerido',
				(v) => v.length <= 50 || 'El nombre debe tener menos de 50 carácteres',
			],
			areaRules: [(v) => !!v || 'Área es requerido'],
			responsablesRules: [(v) => !!v || 'El responsable es requerido'],
			serieRules: [
				(v) => !!v || 'Número de serie es requerido',
				(v) => v.length <= 50 || 'La serie debe tener menos de 50 carácteres',
			],
			typeRules: [(v) => !!v || 'El tipo es requerido'],
			purchaseValue: [
				(v) => !!v || 'Valor de compra de repuesto es requerido',
				(v) => this.checkIntegerPart(v) || 'La parte entera debe tener un máximo de 6 dígitos',
				(v) => /^[0-9]*(\.[0-9]{0,2})?$/.test(v) || 'Solo se permiten números con hasta dos decimales'
			],
		};
	},
	computed: {
		...mapState({
			companies: (state) => state.auth.user?.companies,
		}),
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
		...mapState('area', ['allAreas']),
		...mapState('spareType', ['allSpareTypes']),
	},
	created() {
		this.getUsersActive();
	},
	methods: {
		...mapActions('security', ['getUsersActive']),
		...mapActions('spare', ['createSpare']),

		checkIntegerPart(value) {
			if (!value) {
				return true;
			}
			const stringValue = value.toString();
			const integerPart = stringValue.split('.')[0];
			return integerPart.length <= 6;
		},

		dialogInput(value) {
			// this.$refs.button.$el.blur();
			// this.dialog = value;
			this.$nextTick(() => {
				this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					this.sparesForm = {
						name: '',
						area: null,
						responsible_id: null,
						serial_number: '',
						spare_type: null,
						purchase_value: null,
					},
						this.listError = [];
				}
			})
		},
		
		async saveSpare() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.listError = [];
			const purchaseValue = parseFloat(this.sparesForm.purchase_value);
			const data = {
				...this.sparesForm,
				purchaseValue,
				company_id: parseInt(localStorage.getItem('company_id')),
			};
			this.loadingSave = true;
			const { ok, error } = await this.createSpare(data);
			if (ok) {
				this.dialog = false;
				//emits
				await this.$emit('getSpares');
				await this.$emit('closeCreateSpareDialog');
				this.sparesForm = {
					name: '',
					area: '',
					responsible_id: '',
					serial_number: '',
					spare_type: '',
					purchase_value: '',
				};
				this.$refs?.form?.resetValidation();
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
			this.loadingSave = false;
		},
		close() {
			this.dialog = false;
			this.sparesForm = {
				name: '',
				area: null,
				responsible_id: null,
				serial_number: '',
				spare_type: null,
				purchase_value: null,
			};
			this.listError = [];
			this.$refs?.form?.resetValidation();
		},
	},
	components: {
		ValidationAlert,
	},
};
