export default {
  name: "RegisterTransfer",
  data() {
    return {
      headers: [
        {
          text: 'Activo',
          align: 'start',
          sortable: false,
          value: 'asset',
        },
        {
          text: 'Número de serie',
          align: 'start',
          sortable: false,
          value: 'num_serie',
        },
        {
          text: 'Acciones',
          sortable: false,
          value: 'actions',
      },
      ],
      items: [],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Inventario",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'AssetsPermissions',
          },
        },
        {
          text: "Lista de Traslados",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'ListTransfer',
          },
        },
        {
          text: "Registrar Traslado",
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: '/',
          // },
        },
      ];
    },
  },
  created() {
    this.loadDataDummy()
  },
  methods: {
    loadDataDummy() {
      this.items = [
        {
          asset: 'Iphone',
          num_serie: 'abc123',

          id: 1
        },
        {
          asset: 'Iphone',
          num_serie: 'abc123',

          id: 2
        },
        {
          asset: 'Iphone',
          num_serie: 'abc123',

          id: 3
        },
        {
          asset: 'Iphone',
          num_serie: 'abc123',

          id: 4
        },
        {
          asset: 'Iphone',
          num_serie: 'abc123',

          id: 5
        }
      ]
    },
    async save() {
      this.$swal({
        text: '¿Estás seguro de trasladar este activo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, trasladar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'ListTransfer' });
        }
      });
    }
  }
};