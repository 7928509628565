import { mapState, mapActions } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
export default {
	name: 'UpdateSparesDialog',
	data() {
		return {
			// dialog: false,
			listError: [],
			valid: false,
			loadingSave: false,
			sparesForm: {
				name: '',
				area: null,
				responsible_id: null,
				serial_number: '',
				spare_type: null,
				cost: null,
			},
			nameRules: [
				(v) => !!v || 'Nombre de Repuesto es requerido',
				(v) =>
					(!!v && v.length <= 50) ||
					'El nombre debe tener menos de 50 carácteres',
			],
			areaRules: [(v) => !!v || 'Área es requerida'],
			responsablesRules: [(v) => !!v || 'El responsable es requerido'],
			serieRules: [
				(v) => !!v || 'Número de serie es requerido',
				(v) =>
					(!!v && v.length <= 50) ||
					'La serie debe tener menos de 50 carácteres',
			],
			typeRules: [(v) => !!v || 'El tipo es requerido'],
			costRules: [
				(v) => !!v || 'Costo de repuesto es requerido',
				(v) => this.checkIntegerPart(v) || 'La parte entera debe tener un máximo de 6 dígitos',
				(v) => /^[0-9]*(\.[0-9]{0,2})?$/.test(v) || 'Solo se permiten números con hasta dos decimales'
			],
		};
	},
	props: {
		value: Boolean,
		item: Object,
	},
	watch: {
		dialog(newVal) {
			if (newVal) {
				this.sparesForm = {
					name: this.item.name,
					area: this.item.area.id,
					responsible_id: this.item?.responsible_id?.id || null,
					serial_number: this.item.serial_number,
					spare_type: this.item.spare_type.id,
					cost: this.item.cost,
				};
			}
		},
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		...mapState('area', ['allAreas']),
		...mapState('spareType', ['allSpareTypes']),
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
	},
	methods: {
		...mapActions('spare', ['updateSpare', 'listSpare']),
		...mapActions('security', ['getUsersActive']),
		...mapActions('area', ['listAllAreas']),
		...mapActions('spareType', ['listAllSpareTypes']),

		checkIntegerPart(value) {
			if (!value) {
				return true;
			}
			const stringValue = value.toString();
			const integerPart = stringValue.split('.')[0];
			return integerPart.length <= 6;
		},

		close() {
			this.dialog = false;
			(this.sparesForm = {
				name: '',
				area: null,
				responsible_id: null,
				serial_number: '',
				spare_type: null,
				cost: null,
			}),
				(this.listError = []);
			this.$refs?.form?.resetValidation();
		},
		async save() {
			if (!this.$refs?.form?.validate()) {
				return;
			}

			this.listError = [];

			const cost = parseFloat(this.sparesForm.cost);

			const data = {
				...this.sparesForm,
				cost,
				//company_id: parseInt(localStorage.getItem("company_id")),
			};
			this.loadingSave = true;
			const { ok, error } = await this.updateSpare({
				id: this.item.id,
				data: data,
			});
			if (ok) {
				this.dialog = false;
				//emit
				await this.$emit('getSpares');
				//limpiar form
				(this.sparesForm = {
					name: '',
					area: '',
					responsible_id: '',
					serial_number: '',
					spare_type: '',
					cost: '',
				}),
					this.$refs.form.resetValidation();
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
			this.loadingSave = false;
		},
		dialogInput(value) {
			this.dialog = value;
			if (!value) {
				this.$nextTick(() => {
					(this.sparesForm = {
						name: '',
						area: null,
						responsible_id: null,
						serial_number: '',
						spare_type: null,
						cost: null,
					}),
						(this.listError = []);
					this.$refs.form.resetValidation();
				});
			}
		},
	},
	components: {
		ValidationAlert,
	},
};
