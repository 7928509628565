<template>
	<AssetsPermissions />
</template>
<script>
//import HomePermissions from '@/components/permissions/home/index.vue';
//import AssetsPermissions from '@/components/inventory/assets/index.vue';
import AssetsPermissions from '@/components/inventory/Tabs/AssetsTab/assetsTab.vue'

export default {
	name: 'AssetsInventoryPage',
	components: { AssetsPermissions },
};
</script>
