import CreateAssetsDialog from '../../elements/CreateAssetsDialog';
import ImportMassiveDialog from '../../elements/ImportMassiveDialog';
import CancelActiveDialog from '../../elements/CancelActiveDialog';
import DeleteAssetDialog from '../../elements/DeleteAssetsDialog';
import UpdateAssetDialog from '../../elements/UpdateAssetsDialog';
import ReestablishAssetDialog from '../../elements/ReestablishAssetDialog';
import ValidateActivity from '@/components/global/ValidateActivity.vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { showError } from '@/helpers/globalHelpers';

export default {
	name: 'AssetsTab',
	data: () => ({
		prevStatus: null,
		tempItemStatusChange: null,
		searchTimeout: null,
		searchValue: '',
		//
		dialog: false,
		editDialog: false,
		deleteDialog: false,
		item: {},
		cancelActiveDialog: false,
		reestablishAssetDialog: false,
		//status: [{ name: 'ACTIVO' }, { name: 'INACTIVO' }],
		status: [
			{ name: 'DISPONIBLE' },
			{ name: 'PREASIGNADO' },
			{ name: 'ASIGNADO' },
			{ name: 'REVISION' },
			{ name: 'MANTENIMIENTO' },
			{ name: 'BAJA' },
		],
		headers: [
			{ text: 'Servicio', sortable: false, value: 'service.name' },
			{ text: 'PEP', sortable: false, value: 'pep' },
			{ text: 'AF', sortable: false, value: 'af' },
			{ text: 'Etiqueta', sortable: false, value: 'tag' },
			{ text: 'Nombre de Activo', sortable: false, value: 'name' },
			{ text: 'Número de serie', sortable: false, value: 'serial_number' },
			{ text: 'Tipo', sortable: false, value: 'asset_type.name' },
			{ text: 'Estado', sortable: false, value: 'status', align: 'center' },
			{ text: 'Termino de vida útil', sortable: false, value: 'end_useful_life', align: 'center' },
			{ text: 'Valor residual', sortable: false, value: 'residual_value', align: 'center' },
			{
				text: 'Usuario Asignado',
				sortable: false,
				value: 'assigned_user.fullName',
			},
			{ text: 'Acciones', value: 'actions', sortable: false },
		],
		loading: false,
		loadingExportAsset: false,
		assetsFilters: {
			page: 1,
			limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
			pagination: true,
			asset_type: null,
			status: null,
			//
			assigned_user: null,
			service_id: null,
			management_type: null,
		},
		managementTypes: [
			{ name: 'Gerencia', value: 'management' },
			{ name: 'Area', value: 'area' }
		],
	}),

	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InventoryHome',
					},
				},
				{
					text: 'Activos',
					link: true,
					exact: true,
					disabled: true,
					// to: {
					// 	name: "InventoryHome",
					// },
				},
			];
		},
		...mapState('asset', [
			'assets',
			'assetsPagination',
			'loadingAsset',
			'filtersAsset',
		]),
		...mapState('assetType', ['allAssetTypes']),
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
		filters: {
			get() {
				return this.assetsFilters || {};
			},
			set(value) {
				this.$store.commit('asset/SET_FILTER_ASSET', {
					...value,
				});
			},
		},
		...mapState('security', ['costCenters']),
	},
	created() {
		//
		this.getUsersActive();
		this.getAssets();
		this.listAllAreas();
		this.listAllAssetTypes();
		this.getCostCenters();
	},

	methods: {
		...mapActions('area', ['listAllAreas']),
		...mapActions('asset', ['listAsset', 'cleanAssets', 'downloadExportAssets']),
		...mapActions('assetAssign', ['notificationManual']),
		...mapActions('assetType', ['listAllAssetTypes']),
		...mapActions('security', ['getUsersActive']),
		...mapActions('security', ['getCostCenters']),

		debounceSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.assetsFilters.name = this.searchValue; // o el nombre que desees para tu parámetro de búsqueda
				this.getAssets();
			}, 800);
		},

		customFilter(item, queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},

		async getAssets() {
			this.loading = true;
			const { error } = await this.listAsset(this.assetsFilters);
			if (error) showError(error);
			this.loading = false;
		},

		momentFormat(date) {
			moment.locale('es');
			return moment(date).format('DD/MM/YYYY');
		},

		dataUser(assigned_user) {
			if (!assigned_user) {
				return '-';
			}
			return assigned_user.fullName;
		},

		editItem(item) {
			this.item = Object.assign({}, item);
			this.editDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		deleteItem(item) {
			this.item = Object.assign({}, item);
			this.deleteDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		async closeDeleteDialog() {
			if (this.assets.length == 1 && this.assetsFilters.page > 1) {
				this.assetsFilters.page = this.assetsFilters.page - 1;
			}
			await this.getAssets();
		},

		async closeCreateDialog() {
			this.assetsFilters.asset_type = null;
			this.assetsFilters.status = null;
			this.assetsFilters.name = null;
			this.assetsFilters.page = 1;
			this.searchValue = '';
			this.assetFilters.service_id = null,
			this.assetFilters.management_type = null,
			await this.getAssets();
		},

		async searchAssets() {
			this.assetsFilters.page = 1;
			await this.getAssets();
		},

		updatePageAfterDelete(totalItems) {
			const totalPages = Math.ceil(totalItems / this.assets.limit);
			if (this.assets.page > totalPages) {
				this.$store.commit('asset/SET_PAGE', totalPages);
				this.listAsset({ page: totalPages, limit: this.assets.limit });
			}
		},
		cancelActive(item) {
			this.item = Object.assign({}, item);
			this.cancelActiveDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		reestablishAsset(item) {
			this.item = Object.assign({}, item);
			this.reestablishAssetDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		toggleActiveStatus(item) {
			this.tempItemStatusChange = item;
			if (item.status === 'INACTIVO') {
				this.cancelActive(item);
			} else {
				this.reestablishAsset(item);
			}
		},
		handleDialogCancel(payload) {
			if (!payload.saved) {
				this.tempItemStatusChange.status =
					this.tempItemStatusChange.status === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO';
			}
			this.tempItemStatusChange = null;
		},

		setColorStatus(status) {
			switch (status) {
				case 'DISPONIBLE':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status
					}
				case 'PREASIGNADO':
					return {
						background: 'light-blue lighten-5',
						color: 'light-blue',
						name: status,
					}
				case 'ASIGNADO':
					return {
						background: 'blue lighten-5',
						color: 'blue',
						name: status
					}
				case 'REVISION':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status
					}
				case 'MANTENIMIENTO':
					return {
						background: 'purple lighten-5',
						color: 'purple',
						name: status
					}
				case 'BAJA':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status
					}
				case 'OBSERVADO':
					return {
						background: 'grey lighten-2',
						color: 'grey',
						name: status
					}
			}
		},

		async downloadTemplate() {
			try {
				this.loadingExportAsset = true;

				const { ok, error } = await this.downloadExportAssets({ ...this.assetsFilters, pagination: false });
				if (!ok) {
					console.error(error); // o muestra un mensaje de error usando una alerta/snackbar, por ejemplo.
				}
				this.loadingExportAsset = false;
			} catch (ex) {
				console.error("Hubo un error al descargar el modelo:", ex);
			}
		},

		async manualNotification(assetId) {
			const { error } = await this.notificationManual({ id: assetId });
			if (error) showError(error);

			this.$swal({
				title: '¡Notificación enviada!',
				text: 'Te notificaremos por correo ¡Agradecemos tu paciencia!',
				icon: 'success',
				confirmButtonColor: '#0039a6',
			});

			// this.$swal({
			// 	text: '¿Estás seguro de enviar una notificación manual?',
			// 	icon: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#0039a6',
			// 	cancelButtonColor: 'grey',
			// 	cancelButtonText: 'Cancelar',
			// 	confirmButtonText: 'Sí, enviarlo',
			// }).then(async (result) => {
			// 	if (result.isConfirmed) {
			// 		const { error } = await this.notificationManual({ id: assetId });
			// 		if (error) showError(error);
			// 	}
			// });
		},

		residualValueCalc(residualValue){
			return residualValue < 0 ? 0:residualValue
		}
	},
	watch: {
		'assets.totalItems': function (newTotalItems) {
			this.updatePageAfterDelete(newTotalItems);
		},
	},
	beforeDestroy() {
		this.cleanAssets();
	},
	destroyed() {
		clearTimeout(this.timeout);
	},
	components: {
		CreateAssetsDialog,
		ImportMassiveDialog,
		CancelActiveDialog,
		DeleteAssetDialog,
		UpdateAssetDialog,
		ReestablishAssetDialog,

		//
		ValidateActivity
	},
};
