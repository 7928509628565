<template>
	<RegisterTransfer />
</template>
<script>

import RegisterTransfer from '@/components/inventory/elements/Transfer/RegisterTransfer/registerTransfer.vue';

export default {
	name: 'RegisterTransferPage',
	components: { RegisterTransfer },
};
</script>
