import { mapState, mapActions } from "vuex";
import ValidationAlert from "@/components/global/ValidationAlert.vue";
export default {
  name: "CreateAssetsDialog",
  data() {
    return {
      dialog: false,
      listError: [],
      valid: false,
      loadingSave: false,
      assetsForm: {
        name: "",
        area: null,
        //responsible_id: null,
        assigned_user_id: null,
        serial_number: "",
        asset_type: null,
        cost: null,
        // unsubscribe_comment: "",
        // unsubscribe_file: "",
        comment: null
      },
      nameRules: [
        (v) => !!v || "Nombre de Activo es requerido",
        (v) => v.length <= 50 || "El nombre debe tener menos de 50 carácteres",
      ],
      areaRules: [(v) => !!v || "Área es requerido"],
      //responsablesRules: [(v) => !!v || "El responsable es requerido"],
      // assignedRules: [(v) => !!v || "El asignado es requerido"],
      serieRules: [
        (v) => !!v || "Número de serie es requerido",
        (v) => v.length <= 50 || "La serie debe tener menos de 50 carácteres",
      ],
      typeRules: [(v) => !!v || "El tipo es requerido"],
      costRules: [
        (v) => !!v || "Costo de activo es requerido",
        (v) => this.checkIntegerPart(v) || 'La parte entera debe tener un máximo de 6 dígitos',
        (v) => /^[0-9]*(\.[0-9]{0,2})?$/.test(v) || 'Solo se permiten números con hasta dos decimales'
      ],
      commentRules: [
        (v) => !!v || "El comentario es requerido",
        (v) => (!v || v.length <= 400) || "El comentario debe tener menos de 400 carácteres",
      ]
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.auth.user?.companies,
    }),
    ...mapState({
      responsibles: (state) => state.security.usersActive,
    }),
    ...mapState("area", ["allAreas"]),
    ...mapState("assetType", ["allAssetTypes"]),
    sortedAllAreas() {
      return [...this.allAreas].sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  created() {
    this.getUsersActive();
  },
  methods: {
    ...mapActions("asset", ["createAsset", "listAsset"]),
    ...mapActions("security", ["getUsersActive"]),

    checkIntegerPart(value) {
      if (!value) {
        return true;
      }
      const stringValue = value.toString();
      const integerPart = stringValue.split('.')[0];
      return integerPart.length <= 6;
    },

    dialogInput(value) {
      this.$nextTick(() => {
        this.$refs.button.$el.blur();
        this.$refs.form.resetValidation();
        this.dialog = value;
        if (!value) {
          this.assetsForm = {
            name: "",
            area: null,
            //responsible_id: null,
            assigned_user_id: null,
            serial_number: "",
            asset_type: null,
            cost: null,
            comment: null
          };
          this.listError = [];
        }
      })
    },

    async saveAsset() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.listError = [];
      //
      const cost = parseFloat(this.assetsForm.cost);
      //
      const data = {
        ...this.assetsForm,
        cost,
        company_id: parseInt(localStorage.getItem("company_id")),
      };
      this.loadingSave = true;

      const { ok, error } = await this.createAsset(data);
      if (ok) {
        this.dialog = false;
        //emits
        await this.$emit('getAssets');
        await this.$emit('closeCreateDialog');
        this.assetsForm = {
          name: "",
          area: "",
          //responsible_id: "",
          assigned_user_id: null,
          serial_number: "",
          asset_type: null,
          cost: "",
          comment: ""
          // unsubscribe_comment: "",
          // unsubscribe_file: "",
        };
        this.$refs?.form?.resetValidation();
      } else {
        this.listError = Array.isArray(error?.data?.message)
          ? error?.data?.message
          : [error?.data?.message] || [];
      }
      this.loadingSave = false;
    },
    close() {
      this.dialog = false;
      this.assetsForm = {
        name: "",
        area: null,
        //responsible_id: null,
        assigned_user_id: null,
        serial_number: "",
        asset_type: null,
        cost: null,
        comment: null
        // unsubscribe_comment: "",
        // unsubscribe_file: "",
      };
      this.listError = [];
      this.$refs?.form?.resetValidation();
    },
  },
  components: {
    ValidationAlert,
  },
};
