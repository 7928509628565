import { mapActions, mapState } from 'vuex';
import CreateSparesDialog from '../../elements/Spare/CreateSparesDialog';
import ImportMassiveSpareDialog from '../../elements/Spare/ImportMassiveSpareDialog';
import CancelActiveSpareDialog from '../../elements/Spare/CancelActiveSpareDialog';
import UpdateSparesDialog from '../../elements/Spare/UpdateSparesDialog';
import DeleteSpareDialog from '../../elements/Spare/DeleteSparesDialog';
import ReestablishSpareDialog from '../../elements/Spare/ReestablishSpareDialog';
import { showError } from '@/helpers/globalHelpers';

export default {
	name: 'SparesTab',
	data: () => ({
		dialog: false,
		tempItemStatusChange: null,
		deleteDialog: false,
		searchTimeout: null,
		searchValue: '',
		editDialog: false,
		loading: false,
		loadingExportSpare: false,
		item: {},
		cancelActiveDialog: false,
		reestablishSpareDialog: false,
		status: [{ name: 'DISPONIBLE' }, { name: 'ASIGNADO' }, { name: 'BAJA' }],
		headers: [
			{
				text: 'Nombre de Repuesto',
				align: 'start',
				sortable: false,
				value: 'name',
			},
			{
				text: 'Número de serie',
				align: 'start',
				sortable: false,
				value: 'serial_number',
			},
			{ text: 'Tipo', sortable: false, value: 'spare_type.name' },
			{ text: 'Estado', sortable: false, value: 'status' },
			// { text: 'Responsable', sortable: false, value: 'responsible_id.fullName' },
			{ text: 'Opciones', value: 'actions', sortable: false },
		],
		sparesFilters: {
			page: 1,
			limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
			pagination: true,
			spare_type: null,
			status: null,
			//
			name: null,
		},
	}),

	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InventoryHome',
					},
				},
				{
					text: 'Repuestos',
					link: true,
					exact: true,
					disabled: true,
					// to: {
					// 	name: "InventoryHome",
					// },
				},
			];
		},
		...mapState('spare', [
			'spares',
			'sparesPagination',
			'loadingSpare',
			'filtersSpare',
		]),
		...mapState('spareType', ['allSpareTypes']),

		hasResgisterPermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'registrarRepuesto',
				'registrarRepuesto'
			);
		},
		hasShowDetailPermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'detalleRepuesto',
				'mostrarRepuesto'
			);
		},
		hasEditPermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'editarRepuesto',
				'editarRepuesto'
			);
		},
		hasDeletePermission() {
			return this.$store.getters['auth/hasActivity'](
				'inventario',
				'detalleRepuesto',
				'eliminarRepuesto'
			);
		},
	},

	created() {
		this.getSpares();
		this.listAllAreas();
		this.listAllSpareTypes();
	},

	methods: {
		...mapActions('area', ['listAllAreas']),
		...mapActions('spareType', ['listAllSpareTypes']),
		...mapActions('spare', ['listSpare', 'cleanSpares','downloadExportSpares']),

		async getSpares() {
			this.loading = true;
			const { error } = await this.listSpare(this.sparesFilters);
			if (error) showError(error);
			this.loading = false;
		},

		async closeCreateSpareDialog() {
			this.sparesFilters.spare_type = null;
			this.sparesFilters.status = null;
			this.sparesFilters.name = null;
			this.sparesFilters.page = 1;
			this.searchValue = '';
			await this.getSpares();
		},

		cancelActive(item) {
			this.item = Object.assign({}, item);
			this.cancelActiveDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		editItem(item) {
			this.item = Object.assign({}, item);
			this.editDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		deleteItem(item) {
			this.item = Object.assign({}, item);
			this.deleteDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		reestablishSpare(item) {
			this.item = Object.assign({}, item);
			this.reestablishSpareDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		async closeDeleteDialog() {
			if (this.spares.length == 1 && this.sparesFilters.page > 1) {
				this.sparesFilters.page = this.sparesFilters.page - 1;
			}
			await this.getSpares();
		},

		async searchSpares() {
			this.sparesFilters.page = 1;
			await this.getSpares();
		},

		debounceSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.sparesFilters.name = this.searchValue;
				this.getSpares();
			}, 800);
		},

		toggleActiveStatus(item) {
			this.tempItemStatusChange = item;
			if (item.status === 'INACTIVO') {
				this.cancelActive(item);
			} else {
				this.reestablishSpare(item);
			}
		},
		handleDialogCancel(payload) {
			if (!payload.saved) {
				this.tempItemStatusChange.status =
					this.tempItemStatusChange.status === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO';
			}
			this.tempItemStatusChange = null;
		},

		setColorStatus(status) {
			switch (status) {
				case 'DISPONIBLE':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status,
					};
				case 'ASIGNADO':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status,
					};
				case 'BAJA':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status,
					};
			}
		},

		async downloadTemplate() {
			try {
				this.loadingExportAsset = true;

				const { ok, error } = await this.downloadExportSpares({ ...this.sparesFilters, pagination: false });
				if (!ok) {
					console.error(error); // o muestra un mensaje de error usando una alerta/snackbar, por ejemplo.
				}
				this.loadingExportAsset = false;
			} catch (ex) {
				console.error("Hubo un error al descargar el modelo:", ex);
			}
		},
	},
	beforeDestroy() {
		this.cleanSpares();
	},
	destroyed() {
		clearTimeout(this.timeout);
	},
	components: {
		CreateSparesDialog,
		ImportMassiveSpareDialog,
		CancelActiveSpareDialog,
		UpdateSparesDialog,
		DeleteSpareDialog,
		ReestablishSpareDialog,
	},
};
